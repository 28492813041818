import React, { useEffect } from "react";
import "../styles/ProjectCardDark.scss";
import { CardImage } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { cookieIsDefined } from "../../../../helpers/cookieHelper";

const ProjectCard = ({
  cardImg,
  title,
  description,
  // link,
  _id,
  directLink,
}) => {
  const navigate = useNavigate();
  const link = cookieIsDefined() ? "/dashboard/show-blog/" + _id : "/show-blog/" + _id;

  console.log(directLink);

  return (
    <div className="project-card-dark">
      {directLink === undefined || directLink === "" ? (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(link);
          }}
        >
          <img src={cardImg} alt={title} className="project-image" />
        </a>
      ) : (
        <a
          href={directLink}
          target="_blank"
          style={{ color: "white", textDecoration: "none" }}
        >
          <img src={cardImg} alt={title} className="project-image" />
        </a>
      )}

      <div className="project-details-dark">
        <h3>{title}</h3>
        <p className="project-description-dark">{description}</p>
        {directLink === undefined || directLink === "" ? (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(link);
            }}
          >
            See Project &gt;
          </a>
        ) : (
          <a
            href={directLink}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            See Project &gt;
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
