import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

export const cookieIsDefined = () => {
    return Cookies.get('jwt_user') !== undefined
}

export const getResetPasswordToken = () => {
    return Cookies.get('reset_password')
}

export const getUser = () => {
    return jwtDecode(Cookies.get('jwt_user'))
}

export const getUserIdFromCookie = () => {
    return jwtDecode(Cookies.get('jwt_user')).userId
}

export const getEmailFromCookie = () => {
    return jwtDecode(Cookies.get('jwt_user')).email
}

export const getAuthRoleFromCookie = () => {
    return jwtDecode(Cookies.get('jwt_user')).role
}

export const getAuthToken = () => {
    return Cookies.get('jwt_user')
}