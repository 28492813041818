import React, { useEffect } from "react";
import "../styles/ProjectCard.scss";
import { CardImage } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { cookieIsDefined } from "../../../../helpers/cookieHelper";

const ProjectCard = ({
  cardImg,
  title,
  description,
  // link,
  _id,
  directLink,
}) => {
  const navigate = useNavigate();
  const link = cookieIsDefined() ? "/dashboard/show-blog/" + _id : "/show-blog/" + _id;

  return (
    <div className="project-card-light">
      {directLink === undefined || directLink === "" ? (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(link);
          }}
          
        >
          <img src={cardImg} alt={title} className="project-image" />
        </a>
      ) : (
        <a
          href={directLink}
          target="_blank"
          style={{ color: "white", textDecoration: "none" }}
        >
          <img src={cardImg} alt={title} className="project-image" />
        </a>
      )}

      <div className="project-details-light">
        <h3>{title}</h3>
        <p className="project-description-light">{description}</p>
        {directLink === undefined || directLink === "" ? (
          <a
            style={{
              cursor: "pointer",
              border: "none",
              color: "#3913DE",
            }}
            onClick={() => {
              navigate(link);
            }}
          >
            See Project &gt;
          </a>
        ) : (
          <a
            href={directLink}
            target="_blank"
            style={{ textDecoration: "none", color: "#3913DE" }}
          >
            See Project &gt;
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
